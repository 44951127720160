<template>
  <span class="icon" :class="color">
    <svg :width="size" :height="size * 1.2" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect x="18" y="9" width="4" height="4" rx="2" transform="rotate(90 18 9)" stroke="currentColor" stroke-width="2"></rect> <rect x="18" y="17" width="4" height="4" rx="2" transform="rotate(90 18 17)" stroke="currentColor" stroke-width="2"></rect> <rect x="3" y="7" width="4" height="4" rx="2" transform="rotate(-90 3 7)" stroke="currentColor" stroke-width="2"></rect> <path d="M5 8V15C5 16.8856 5 17.8284 5.58579 18.4142C6.17157 19 7.11438 19 9 19H14" stroke="currentColor" stroke-width="2"></path> <path d="M5 7V7C5 8.88562 5 9.82843 5.58579 10.4142C6.17157 11 7.11438 11 9 11H14" stroke="currentColor" stroke-width="2"></path> </g></svg>
  </span>
</template>
<script>
export default {
  props: {
      color: {
        type: String,
        default: "has-text-grey"
      },
      size: {
        type: Number,
        default: 18
      }
  }
};
</script>